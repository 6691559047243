import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Link,
  Paper,
  RadioGroup,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { ErrorOutline } from '@mui/icons-material'

import HSBWaterMark from '../../images/hsb-watermark.svg'
import RadioButton from '../../components/RadioButton'
import Footer from '../../components/Footer'

const emailPattern = /^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

const Acknowledge = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const code = params.code ?? searchParams.get('code')
  const navigate = useNavigate()
  const theme = useTheme()

  const [emailOrPhoneText, setEmailOrPhoneText] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [inputError, setInputError] = useState<string>('')
  const [radioError, setRadioError] = useState<string>('')
  const [data, setData] = useState<GetAlarmsResponse | undefined>(undefined)
  const [selectedResponse, setSelectedResponse] = useState<AcknowledgeType | undefined>(undefined)

  const bigScreen = useMediaQuery(theme.breakpoints.up('md'))

  const sensorLocation = data?.metadata?.device_location
  useEffect(() => {
    const fetchData = async () => {
      setError('')
      setLoading(true)
      try {
        const res = await fetch(`/api/v2/alarms/response/${code}`)
        const body = await res.json()

        if (body.message) {
          if (body.message === 'alarms_status code not found or expired') {
            setError('The alarm code you are using is either expired or does not exist')
          } else if (body.message === 'alarms_acknowledge code does not match required length') {
            setError('Invalid code length provided.')
          } else {
            setError(body.message)
          }
        } else {
          if (body.type) {
            navigate(`/submit?type=${body.type}`, { state: body })
          } else {
            setData(body)
          }
        }
      } catch {
        setError('Unknown error occurred')
      } finally {
        setLoading(false)
      }
    }

    if (code && code.length > 0) {
      fetchData()
    }
  }, [code, navigate])

  const submitResponse = async (response: AcknowledgeType) => {
    if (response === 'noack') {
      navigate(`/submit?type=${response}`, { state: data })
      return
    }

    const isEmail = emailOrPhoneText.match(emailPattern)
    let error = false
    // error if the input is empty; they don't want an error message
    // but they want the placeholder & box outline to be red anyways.
    if (!emailOrPhoneText) {
      setInputError('noinput')
      error = true
    }

    // error if the phone number is not the "right number of digits" ...
    if (!isEmail && emailOrPhoneText.length > 0) {
      const digitsOnly = emailOrPhoneText.replace(/\D/g, '')
      if (digitsOnly.length < 10) {
        setInputError('Please enter a valid phone number or email address')
        error = true
      }
    }

    // if there is no error yet then that means there is no input error
    if (!error) {
      setInputError('')
    }

    if (!response) {
      setRadioError('Please make a selection to continue')
      error = true
    } else {
      setRadioError('')
    }

    if (error) {
      return
    }

    try {
      const payload = {
        code,
        // if the input text matches a email regex, use it as an email
        email: isEmail ? emailOrPhoneText : undefined,
        // if the input text not does match a email regex, use it as a phone
        phone: !isEmail ? emailOrPhoneText : undefined,
        type: response,
      }
      const res = await fetch('/api/v2/alarms/response/acknowledge', {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      const body = await res.json()

      if (body.message) {
        if (
          body.message === 'alarms_acknowledge must provide email or phone number associated with account' ||
          body.message === 'alarms_acknowledge matching user not found'
        ) {
          setInputError(
            `The ${isEmail ? 'email' : 'number'} you entered does not match our records. Please re-enter your ${isEmail ? 'email address' : 'phone number'}.`
          )
        } else {
          setInputError(body.message)
        }
      } else {
        navigate(`/submit?type=${response}`, { state: data })
      }
    } catch {
      setInputError('Unknown error occurred')
    } finally {
    }
  }

  if (loading) {
    return <CircularProgress />
  }

  const errorPage = !data || error

  const supportPhone = '(844) 486-1866'
  const supportEmail = 'sensorsupport@hsb.com'

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', minHeight: '100vh', minWidth: '100vw' }}>
      <Paper
        sx={
          bigScreen
            ? {
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                maxWidth: '840px',
                width: '50%',
                borderRadius: '16px',
                flexGrow: 1,
                marginY: '60px',
                marginX: '300px',
              }
            : {}
        }
        elevation={bigScreen ? 4 : 0}
      >
        <Grid container={true} spacing={2} p={3} py={5} sx={{ maxWidth: '400px' }} justifyContent="center">
          <Grid item={true} container={true} xs={12} justifyContent="center">
            <img src={HSBWaterMark} alt="Sensor Solutions by HSB" width="230px" />
          </Grid>

          <Grid item={true} container={true} xs={12} justifyContent="center">
            <ErrorOutline color="error" sx={{ fontSize: !errorPage ? '40px' : '60px' }} />
          </Grid>

          {!errorPage ? (
            <>
              <Grid item={true} xs={12}>
                <Typography variant="h5" align="center">
                  Alert triggered at
                </Typography>
                <Typography variant="h5" align="center" sx={{ fontWeight: '600' }}>
                  {data.location}
                </Typography>
              </Grid>
              <Grid item={true} xs={12}>
                <Typography variant="body1" align="center" color="text.secondary">
                  Sensor Name: {data.name}
                </Typography>
                {sensorLocation && (
                  <Typography variant="body1" align="center" color="text.secondary">
                    Sensor Location: {sensorLocation}
                  </Typography>
                )}
              </Grid>

              {bigScreen && (
                <Grid item={true} xs={12}>
                  <Divider sx={{ marginY: '36px' }} />
                </Grid>
              )}

              <Grid item={true} xs={12}>
                <Typography variant="body1" align="center" color="primary">
                  Please enter your phone number or email for verification and select your next action
                </Typography>
              </Grid>

              <Grid item={true} xs={12}>
                <TextField
                  placeholder="Enter phone number or email address"
                  fullWidth={true}
                  value={emailOrPhoneText}
                  onChange={(e) => setEmailOrPhoneText(e.target.value)}
                  error={!!inputError}
                  helperText={inputError === 'noinput' ? '' : inputError}
                  // designs wants the placeholder to be red if there's an error :shrug:
                  sx={
                    inputError
                      ? {
                          '& .MuiInputBase-input::placeholder': {
                            color: theme.palette.error.main,
                            opacity: 1,
                          },
                          '& .MuiFormHelperText-root': {
                            textAlign: 'center',
                          },
                        }
                      : {}
                  }
                />
              </Grid>

              <Grid item={true} container={true} xs={12} justifyContent="center">
                {radioError && (
                  <Typography variant="body1" color="error">
                    {radioError}
                  </Typography>
                )}
                <FormControl sx={{ padding: '16px' }}>
                  <RadioGroup
                    name="radio-buttons-group"
                    defaultValue=""
                    value={selectedResponse ?? ''}
                    onChange={(e) => {
                      setSelectedResponse(e.target.value as AcknowledgeType)
                    }}
                  >
                    <RadioButton value="ack" label="Acknowledge" subLabel="I will take action to address this alert" />
                    <RadioButton value="test" label="Ignore" subLabel="I won't take action (test or non-issue)" />
                    <RadioButton value="noack" label="Decline" subLabel="I cannot take action at this time" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item={true} container={true} xs={12} justifyContent="center">
                <Button variant="contained" color="primary" onClick={() => submitResponse(selectedResponse!)} sx={{ width: 250, borderRadius: '20px' }}>
                  submit
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Grid item={true} xs={12}>
                <Typography variant="h5" sx={{ fontWeight: 'bold' }} textAlign="center">
                  Well, that didn't work.
                </Typography>
              </Grid>
              <Grid item={true} xs={12}></Grid>
              <Grid item={true} xs={12}>
                <Typography variant="body1" align="center" color="text.secondary">
                  The alarm code you are using is either expired or does not exist. Please check the url again to see if you missed something.
                  <br />
                  <br />
                  If you still have issues, our support team will be happy to help you.
                </Typography>
              </Grid>
              <Grid item={true} xs={12}></Grid>
              <Grid item={true} container={true} xs={12} justifyContent="center">
                <Grid>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }} textAlign="center">
                    Contact us:
                  </Typography>
                  <Link variant="caption" href={`mailto:${supportEmail}`} underline="none">
                    <Typography textAlign="center" variant="body1">
                      {supportEmail}
                    </Typography>
                  </Link>
                  <Link variant="caption" href={`tel:${supportPhone}`} underline="none">
                    <Typography textAlign="center" variant="body1">
                      {supportPhone}
                    </Typography>
                  </Link>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>

      <Footer data={!errorPage ? data : 'error'} />
    </Box>
  )
}

export default Acknowledge
