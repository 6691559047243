import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { PRIVACY_POLICY_LINK, TERMS_OF_USE_LINK } from '../utils/constants'

const Divider = () => (
  <Typography variant="caption" sx={{ paddingX: '8px' }}>
    |
  </Typography>
)

const Footer = ({ data }: { data: GetAlarmsResponse | 'error' }) => {
  const email = 'sensorsupport@hsb.com'
  const phone = data !== 'error' ? data.footerHelp : '(844) 468-1866'
  const theme = useTheme()

  const bigScreen = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: `${theme.palette.secondary.main}40`,
        width: '100%',
      }}
    >
      <Box
        sx={{
          maxWidth: '840px',
          width: bigScreen ? '50%' : '100%',
          textAlign: bigScreen ? undefined : 'center',
          padding: 2.5,
        }}
      >
        <Typography variant="caption" color="text.secondary">
          Sensor Support Team
        </Typography>
        <Box>
          <Link variant="caption" href={`tel:${phone}`} underline="none">
            {phone}
          </Link>
          <Divider />
          <Link variant="caption" href={`mailto:${email}`} underline="none">
            {email}
          </Link>
        </Box>

        {/* <Typography variant="caption" color="text.secondary">
        Download the Protect App to manage your alerts
      </Typography> */}

        <Box color="text.secondary">
          <Typography variant="caption">©2022 Meshify, Inc.</Typography>
          <Divider />
          <Link variant="caption" href={PRIVACY_POLICY_LINK} underline="none" color="text.secondary">
            Privacy Policy
          </Link>
          <Divider />
          <Link variant="caption" href={TERMS_OF_USE_LINK} underline="none" color="text.secondary">
            Terms of Use
          </Link>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
