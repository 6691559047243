import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material'
import { createGenerateClassName, StylesProvider } from '@mui/styles'

export const palette = {
  type: 'light' as 'light',
  background: {
    default: '#FFFFFF',
  },
  primary: {
    main: '#0078EC',
    contrastText: '#fff',
  },
  secondary: {
    main: '#BBE0FF',
    contrastText: '#fff', // this will make secondary buttons have white text instead of black
  },
  success: {
    main: '#47B282',
  },
  info: {
    main: '#0868DA',
  },
  error: {
    main: '#CC0022',
  },
}

// we added custom prefix to material classnames
// because autogenerated classNames were for some reason same as some classes that mui-datatables package generated
const generateClassName = createGenerateClassName({
  productionPrefix: 'muiCustom',
})

// this is how we override styles in mui5
const components = {}

const muiCache = createCache({
  key: 'mui',
  prepend: true,
})

export const protectAcknowledgeTheme = createTheme({
  typography: {
    fontFamily: ['Work Sans', 'sans-serif'].join(','),
    h6: {},
    h5: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '24px',
      lineHeight: '36px',
    },
    h4: {},
    h3: {
      fontWeight: 600,
      fontStyle: 'normal',
      fontSize: '18px',
      lineHeight: '20px',
    },
    h2: {},
    h1: {},
    body1: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    body2: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '20px',
    },
    caption: {
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
    },
    button: {
      fontWeight: 'bold',
    },
  },
  palette: {
    ...palette,
  },
  components,
})

const MaterialThemeProvider: React.FC = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst={true}>
      <CacheProvider value={muiCache}>
        <StylesProvider generateClassName={generateClassName}>
          <ThemeProvider theme={protectAcknowledgeTheme}>{children}</ThemeProvider>
        </StylesProvider>
      </CacheProvider>
    </StyledEngineProvider>
  )
}

export default MaterialThemeProvider
