import { CssBaseline } from '@mui/material'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Acknowledge from './routes/acknowledge'
import Submit from './routes/submit'
import MaterialThemeProvider from './providers/theme-provider'

const App = () => {
  return (
    <BrowserRouter>
      <MaterialThemeProvider>
        <CssBaseline />
        <Routes>
          <Route path="/">
            <Route path="submit" element={<Submit />} />
            <Route path="acknowledge" element={<Acknowledge />} />
            <Route path=":code" element={<Acknowledge />} />
          </Route>
        </Routes>
      </MaterialThemeProvider>
    </BrowserRouter>
  )
}

export default App
