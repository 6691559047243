import { FormControlLabel, Radio, Typography } from '@mui/material'

const RadioButton = ({ value, label, subLabel }: { value: string; label: string; subLabel: string }) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            marginY: '-10px',
            alignSelf: 'start',
          }}
        />
      }
      label={
        <>
          <Typography variant="h3" color="primary">
            {label}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {subLabel}
          </Typography>
        </>
      }
      sx={{ paddingBottom: '16px' }}
    />
  )
}

export default RadioButton
