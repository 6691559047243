import { Box, Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useEffect } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { CheckCircle, HighlightOff, ErrorOutline } from '@mui/icons-material'

import HSBWaterMark from '../../images/hsb-watermark.svg'
import Footer from '../../components/Footer'

const Submit = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const theme = useTheme()
  const { state: data }: { state: GetAlarmsResponse } = useLocation()

  const bigScreen = useMediaQuery(theme.breakpoints.up('md'))

  const date = new Date(data.alarmTs)
  const formattedDate = date?.toLocaleString() ?? data.alarmTs

  const getAcknowledgeType = (): AcknowledgeType | undefined => {
    const type = searchParams.get('type')
    if (type === 'ack') return 'ack'
    else if (type === 'noack') return 'noack'
    else if (type === 'test') return 'test'

    return undefined
  }

  useEffect(() => {
    if (!data) {
      navigate('/')
    }
  }, [data, navigate])

  if (!data) {
    return <div />
  }

  const IconType = getAcknowledgeType() === 'ack' ? CheckCircle : getAcknowledgeType() === 'test' ? HighlightOff : ErrorOutline
  const iconColor = getAcknowledgeType() === 'ack' ? 'success' : getAcknowledgeType() === 'test' ? 'info' : 'error'
  const acknowledgeTitle = getAcknowledgeType() === 'ack' ? 'Acknowledged' : getAcknowledgeType() === 'test' ? 'Ignored' : 'Declined'

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', minHeight: '100vh', minWidth: '100vw' }}>
      <Paper
        sx={
          bigScreen
            ? {
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'center',
                maxWidth: '840px',
                width: '50%',
                borderRadius: '16px',
                flexGrow: 1,
                marginY: '60px',
                marginX: '300px',
              }
            : {}
        }
        elevation={bigScreen ? 4 : 0}
      >
        <Grid container={true} spacing={2} p={3} py={5} sx={{ maxWidth: '400px' }} justifyContent="center">
          <Grid item={true} container={true} xs={12} justifyContent="center">
            <img src={HSBWaterMark} alt="Sensor Solutions by HSB" width="230px" />
          </Grid>

          <Grid item={true} container={true} xs={12} justifyContent="center">
            <Box sx={{ textAlign: 'center', marginTop: '54px' }}>
              <IconType color={iconColor} sx={{ fontSize: '60px' }} />
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Alert {acknowledgeTitle}
              </Typography>
            </Box>
          </Grid>

          <Grid item={true} xs={6}>
            <Divider sx={{ marginY: '36px' }} />
          </Grid>

          <Grid item={true} xs={12} sx={{ textAlign: 'center' }}>
            {data?.location && (
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {data.location}
              </Typography>
            )}
            {data?.alarmTs && (
              <Typography variant="body1" color="text.secondary">
                Alert Time: {formattedDate}
              </Typography>
            )}
            {data?.name && (
              <Typography variant="body1" color="text.secondary">
                Sensor Name: {data.name}
              </Typography>
            )}
            {data?.metadata?.device_location && (
              <Typography variant="body1" color="text.secondary">
                Sensor Location: {data.metadata.device_location}
              </Typography>
            )}
            {data?.type && (
              <Typography variant="body1" color="text.secondary">
                Alert Type: {data.type}
              </Typography>
            )}
          </Grid>

          {getAcknowledgeType() === 'noack' && (
            <Grid item={true} xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="body1" color="primary.main" sx={{ fontStyle: 'italic' }}>
                ** This alert still requires attention by someone at your location. **
              </Typography>
            </Grid>
          )}

          {data?.metadata?.device_location_note && (
            <Grid item={true} xs={12} sx={{ textAlign: 'center' }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                Notes
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {data?.metadata?.device_location_note}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Paper>

      <Footer data={data} />
    </Box>
  )
}

export default Submit
